// HOMEPAGE

import React, { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../apiConfig";
import { Link } from "react-router-dom";

import classes from "./Homepage.module.css";
import PdfViewer from "./PdfViewer.tsx";

import brandLogo from "../assets/logos/brand-logo-dark.jpg";
import bookLogo from "../assets/logos/book-logo-dark.png";
import GoToTop from "../components/GoToTop";

function Homepage() {
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [selectedPdfUrl, setSelectedPdfUrl] = useState("");

  const handleOpenPdf = async (pdfUrl) => {
    console.log(pdfUrl);
    try {
      const response = await axios.get(pdfUrl, {
        responseType: "blob", // Important: responseType should be set to "blob"
        // withCredentials: true,
      });
      const pdf = response.data;
      const url = URL.createObjectURL(pdf);
      setSelectedPdfUrl(url);
      //   setIsLoading(false);
      setOpenPdfViewer(true);
    } catch (error) {
      console.log("Error fetching PDF file:", error);
    }
  };

  function pdfViewerCloseHandler() {
    setOpenPdfViewer(false);
  }

  const pdfViewer = (
    <PdfViewer pdf={selectedPdfUrl} onClose={pdfViewerCloseHandler} />
  );

  if (openPdfViewer) {
    return pdfViewer;
  }

  return (
    <div className={classes.mainContainer}>
      <GoToTop />
      {/* HEADER */}
      <div className={classes.header}>
        <Link to="https://kashispace.live/">
          <img className={classes.brand} src={brandLogo} alt="brand-logo" />
        </Link>
        <Link
          onClick={() =>
            handleOpenPdf(`${BASE_URL}/user/homepage/files/whyPdf`)
          }
          className={classes.headerText}
          // target="_blank"
        >
          Introduction
        </Link>
      </div>

      <div className={classes.innerContainer}>
        {/* BOOK NAME */}
        <div className={classes.bookName}>
          <img src={bookLogo} alt="whitepages-logo" />
        </div>

        {/* FOOTER LINKS */}
        <div className={classes.footerLinks}>
          <Link
            onClick={() =>
              handleOpenPdf(`${BASE_URL}/user/homepage/files/mainPreviewPdf`)
            }
            // target="_blank"
          >
            Preview
          </Link>
          <Link to="/readingspace">Books</Link>
          <Link
            onClick={() =>
              handleOpenPdf(`${BASE_URL}/user/homepage/files/authorPdf`)
            }
            // target="_blank"
          >
            Author
          </Link>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
}

export default Homepage;
