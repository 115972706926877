// open this when user click on a book from slider

import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../apiConfig";

import Header from "../components/Header";
import classes from "./GetAccess.module.css";

import { useParams, Link } from "react-router-dom";
import AnimatedPage from "../components/AnimatedPage";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { Modal, Box } from "@mui/material";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Rating from "@mui/material/Rating";
import Footer from "../components/Footer";
import GoToTop from "../components/GoToTop";

function GetAccess() {
  const [isLoading, setIsLoading] = useState(true);
  const [book, setBook] = useState({});
  const params = useParams();

  const [isReviewsModalOpen, setReviewsModalOpen] = useState(false);
  const [reviewsLoading, setReviewsLoading] = useState(true);
  const [reviews, setReviews] = useState([]);

  const [isPdfModalOpen, setPdfModalOpen] = useState(false);
  const [selectedPdfUrl, setSelectedPdfUrl] = useState("");

  console.log(new Date());

  useEffect(() => {
    axios
      .get(`${BASE_URL}/books/${params.bookId}`)
      .then((res) => {
        console.log(res.data);
        setBook(res.data);
        setIsLoading(false);
        // console.log(book);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [params.bookId]);

  function handleOpenPdfModal(pdfUrl) {
    setSelectedPdfUrl(pdfUrl);
    setPdfModalOpen(true);
  }

  async function handleOpenReviewsModal() {
    setReviewsModalOpen(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/books/${params.bookId}/reviews`
      );
      const fetchedReviews = response.data.reviews;
      console.log(fetchedReviews);
      setReviews(fetchedReviews);
      setReviewsLoading(false);
    } catch (error) {
      console.log("Error fetching reviews:", error);
      setReviewsLoading(false);
    }
  }

  // FUNCTION TO GET DATE (createdAt from review model) AND FORMAT IT FOR DISPLAYING
  const formatReviewDate = (createdAt) => {
    const now = moment(); // Current date and time
    const reviewDate = moment(createdAt);

    if (now.isSame(reviewDate, "day")) {
      // If it's the same day, show the time ago
      return reviewDate.fromNow();
    } else {
      // Otherwise, format as dd mm yyyy
      return reviewDate.format("DD MMM YYYY");
    }
  };

  const pdfModal = (
    <Modal open={isPdfModalOpen} onClose={() => setPdfModalOpen(false)}>
      <Fade in={isPdfModalOpen}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            height: "500px",
            bgcolor: "#FFF",
            borderRadius: "4px",
            boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)",
          }}
        >
          <iframe
            src={selectedPdfUrl}
            title={book.title}
            width="100%"
            height="100%"
          />
        </Box>
      </Fade>
    </Modal>
  );

  const reviewsModal = (
    <Modal open={isReviewsModalOpen} onClose={() => setReviewsModalOpen(false)}>
      <Fade in={isReviewsModalOpen}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            maxHeight: "500px",
            padding: "10px", //new
            overflow: "auto", //new
            bgcolor: "#FFF",
            borderRadius: "4px",
            boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)",
          }}
        >
          <div className={classes.reviewsHeader}>
            <h4>Reader Reviews</h4>
          </div>

          {reviewsLoading ? (
            <div className={classes.loadingDiv}>
              <CircularProgress color="inherit" />
            </div>
          ) : reviews.length > 0 ? (
            reviews.map((review) => (
              <div key={review.id}>
                <Paper sx={{ marginBottom: "10px" }} elevation={3}>
                  <div className={classes.reviewCardContent}>
                    <div className={classes.rating}>
                      <div className={classes.nameWithDate}>
                        <p className={classes.name}>{review.user}</p>
                        <p className={classes.date}>
                          reviewed {formatReviewDate(review.createdAt)}
                        </p>
                      </div>
                      {/* <p className={classes.name}>{review.user}</p> */}
                      <Rating
                        name="rating"
                        size="small"
                        value={review.rating}
                        readOnly
                      />
                    </div>
                    {review.comment && (
                      <p className={classes.comment}>{review.comment}</p>
                    )}
                  </div>
                </Paper>
              </div>
            ))
          ) : (
            <div className={classes.noReviews}>
              <p>No reviews yet!</p>
            </div>
          )}
        </Box>
      </Fade>
    </Modal>
  );

  return (
    <AnimatedPage>
      <GoToTop />
      <div className={classes.mainContainer}>
        <Header />
        {isLoading ? (
          <div className={classes.loadingDiv}>
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <div className={classes.wrapper}>
            <div className={classes.leftColumn}>
              <img
                src={`${BASE_URL}/books/files/${book.coverImg}`}
                alt={`book-cover-${book.title}`}
                className="img-fluid"
              />
              <div className={classes.available}>
                <p className={classes.status}>
                  {book.status === "yes" ? "Available" : "Unavailable"}
                </p>
                <p className={classes.price}>{book.price}/-</p>
              </div>
            </div>
            <div className={classes.rightColumn}>
              <div className={classes.bookActions}>
                <Link
                  onClick={() =>
                    handleOpenPdfModal(
                      `${BASE_URL}/books/files/${book.previewPdf}#view=fitH`
                    )
                  }
                >
                  Preview
                </Link>
                <Link
                  onClick={() =>
                    handleOpenPdfModal(
                      `${BASE_URL}/books/files/${book.noteToReader}#view=fitH`
                    )
                  }
                >
                  Note to Reader
                </Link>
                <Link
                  onClick={() =>
                    handleOpenPdfModal(
                      `${BASE_URL}/books/files/${book.contentTable}#view=fitH`
                    )
                  }
                >
                  Table of Content
                </Link>
                <Link onClick={handleOpenReviewsModal}>Reviews</Link>
              </div>
              <div className={classes.access}>
                <Link to={`/getaccess/${book._id}/details`}>Get Access</Link>
              </div>
            </div>
          </div>
        )}

        {pdfModal}
        {reviewsModal}
        <Footer />
      </div>
    </AnimatedPage>
  );
}

export default GetAccess;
