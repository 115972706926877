// LOGIN/SIGNUP PAGE

// On "clicking why a new book" or "books"

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import classes from "./SignUp.module.css";
import PdfViewer from "./PdfViewer.tsx";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { BASE_URL } from "../apiConfig";

import brandLogo from "../assets/logos/brand-logo-light.jpg";
import bookLogo from "../assets/logos/book-logo-light.png";
import AnimatedPage from "../components/AnimatedPage";
import Footer from "../components/Footer";
import GoToTop from "../components/GoToTop";


function SignUp() {
  const navigate = useNavigate();
  const [isAgree, setIsAgree] = useState(true);
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [selectedPdfUrl, setSelectedPdfUrl] = useState("");
  const [inputValue, setInputValue] = useState({
    fName: "",
    lName: "",
    email: "",
    mobile: "",
    city: "",
    country: "",
    password: "",
    confirmPassword: "",
  });

  const {
    fName,
    lName,
    email,
    mobile,
    city,
    country,
    password,
    confirmPassword,
  } = inputValue;

  function handleOnChange(event) {
    const { name, value } = event.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  }

  function handleTermsCheck(e) {
    setIsAgree((current) => !current);
    if (isAgree) {
      console.log("agree");
    } else if (!isAgree) {
      console.log("don't agree");
    }
    console.log(isAgree);
  }

  // USER SIGN UP
  async function submitHandler(event) {
    event.preventDefault();
    if (!isAgree) {
      if (inputValue.password !== inputValue.confirmPassword) {
        toast.error("Passwords don't match!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      } else {
        try {
          const { data } = await axios.post(
            `${BASE_URL}/signup`,
            {
              ...inputValue,
            },
            { withCredentials: true }
          );
          const { success, message, user } = data;
          if (success) {
            toast.success(message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "colored",
            });
            setTimeout(() => {
              navigate(`/verify-otp/${user._id}`);
            }, 2000);
          } else {
            toast.error(message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "colored",
            });
          }
        } catch (error) {
          console.log(error);
        }
        setInputValue({
          ...inputValue,
          fName: "",
          lName: "",
          email: "",
          mobile: "",
          city: "",
          country: "",
          password: "",
          confirmPassword: "",
        });
      }
    } else {
      toast.info("Please agree to privacy policy!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      console.log("Agree to privacy policy");

      return;
    }
  }

  const handleOpenPdf = async (pdfUrl) => {
    console.log(pdfUrl);
    try {
      const response = await axios.get(pdfUrl, {
        responseType: "blob", // Important: responseType should be set to "blob"
        // withCredentials: true,
      });
      const pdf = response.data;
      const url = URL.createObjectURL(pdf);
      setSelectedPdfUrl(url);
      //   setIsLoading(false);
      setOpenPdfViewer(true);
    } catch (error) {
      console.log("Error fetching PDF file:", error);
    }
  };

  function pdfViewerCloseHandler() {
    setOpenPdfViewer(false);
  }

  const pdfViewer = (
    <PdfViewer pdf={selectedPdfUrl} onClose={pdfViewerCloseHandler} />
  );

  if (openPdfViewer) {
    return pdfViewer;
  }

  return (
    <AnimatedPage>
    <GoToTop/>
      <div className={classes.mainContainer}>
        <div className={classes.header}>
          <div className={classes.headerLogo}>
            <Link to="https://kashispace.live/">
              <img className={classes.brand} src={brandLogo} alt="brand-logo" />
            </Link>
          </div>
          <Link
            onClick={() =>
              handleOpenPdf(`${BASE_URL}/user/homepage/files/whyPdf`)
            }
          >
            Introduction
          </Link>
        </div>

        <div className={classes.welcomeBox}>
          {/* LEFT CONTAINER */}
          <div className={classes.leftContainer}>
            <div className={classes.bookName}>
              <img src={bookLogo} alt="whitepages-logo" />
            </div>
            <div className={classes.footerLinks}>
              <Link
                onClick={() =>
                  handleOpenPdf(
                    `${BASE_URL}/user/homepage/files/mainPreviewPdf`
                  )
                }
              >
                Preview
              </Link>
              <Link
                onClick={() =>
                  handleOpenPdf(`${BASE_URL}/user/homepage/files/authorPdf`)
                }
              >
                Author
              </Link>
            </div>
          </div>
          <hr color="black" />
          {/* RIGHT CONTAINER */}
          <div className={classes.rightContainer}>
            <form onSubmit={submitHandler}>
              <h4>Access Books</h4>
              <input
                onChange={handleOnChange}
                value={fName}
                type="text"
                name="fName"
                placeholder="First Name"
                required
              />
              <input
                onChange={handleOnChange}
                value={lName}
                type="text"
                name="lName"
                placeholder="Last Name"
                required
              />
              <input
                onChange={handleOnChange}
                value={email}
                type="email"
                name="email"
                placeholder="Email"
                required
              />
              <input
                onChange={handleOnChange}
                value={mobile}
                type="tel"
                name="mobile"
                placeholder="Mobile (10 digits)"
                pattern="[0-9]{10}"
                required
              />
              <input
                onChange={handleOnChange}
                value={city}
                type="text"
                name="city"
                placeholder="City"
                required
              />
              <input
                onChange={handleOnChange}
                value={country}
                type="text"
                name="country"
                placeholder="Country"
                required
              />
              <input
                onChange={handleOnChange}
                value={password}
                type="password"
                name="password"
                placeholder="Set Password"
                required
              />
              <input
                onChange={handleOnChange}
                value={confirmPassword}
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                required
              />
              <div className={`${"form-check"} ${classes.termsCheck}`}>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  onChange={handleTermsCheck}
                  value={isAgree}
                  name="termsCheck"
                ></input>

                <label
                  className={`${"form-check-label"} ${classes.termsCheckLabel}`}
                  htmlFor="termsCheck"
                  style={{ textAlign: "center" }}
                  // style={{padding: "5px"}}
                >
                  I have read and agree to the{" "}
                  <Link
                    to="/terms-and-conditions"
                    style={
                      {
                        // color: "rgb(53, 53, 53)",
                        // textDecoration: "underline",
                      }
                    }
                  >
                    Terms of Use
                  </Link>{" "}
                  and{" "}
                  <Link
                    to="/privacy-policy"
                    style={
                      {
                        // color: "rgb(53, 53, 53)",
                        // textDecoration: "underline",
                      }
                    }
                  >
                    Privacy Policy
                  </Link>
                  .
                </label>
              </div>
              <div className={classes.formActions}>
                <div className={classes.signUp}>
                  <button type="submit">
                    Sign Up <ArrowForwardIcon fontSize="small" />
                  </button>
                </div>
                <div className={classes.logIn}>
                  <Link to="/welcome">Already have an account? Log In</Link>
                </div>
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
        <Footer />
      </div>
    </AnimatedPage>
  );
}

export default SignUp;
