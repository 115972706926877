import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../apiConfig";

import classes from "./CompleteBookAdmin.module.css";
import { useParams } from "react-router-dom";

function CompleteBookAdmin() {
  const [openNav, setOpenNav] = useState(false);
  const [book, setBook] = useState({});

  const params = useParams();

  // TO GET A PDF FILE AND DISPLAY IN IFRAME
  useEffect(() => {
    axios
      .get(`${BASE_URL}/books/${params.bookId}`)
      .then((res) => {
        console.log(res.data);
        setBook(res.data);
        // console.log(book);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.bookId]);

  function openNavHandler() {
    setOpenNav(true);
  }

  function closeNavHandler() {
    setOpenNav(false);
  }

  const responsiveSideNavbar = (
    <div className={classes.sideNav} id="sideNav">
      <button onClick={closeNavHandler} className={classes.indexBtn}>
        Index
      </button>

      {/* TO SET THE INDEX PROVIDED BY THE ADMIN THROUGH RICH TEXT EDITOR aka CKE EDITOR */}
      <div dangerouslySetInnerHTML={{ __html: book.index }} />
    </div>
  );

  return (
    <div className={classes.gridContainer}>
      <div onClick={closeNavHandler} className={classes.gridItem}>
        <iframe
          // src={`http://localhost:5000/books/files/${params.pdfFilename}#view=fitH`}
          src={`${BASE_URL}/admin/completePdf/${params.pdfFilename}#view=fitH`}
          title="testPDF"
          height="100%"
          width="100%"
        />
      </div>
      {!openNav ? (
        <div className={classes.gridItem2}>
          <button onClick={openNavHandler} className={classes.indexBtn}>
            Index
          </button>
        </div>
      ) : (
        responsiveSideNavbar
      )}
    </div>
  );
}

export default CompleteBookAdmin;
