import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../apiConfig";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import classes from "./ViewCategory.module.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function ViewCategory(props) {
  const [book, setBook] = useState({});

  // to GET a specific book's details and display them for viewing
  useEffect(() => {
    axios
      .get(`${BASE_URL}/books/${props.bookId}`)
      .then((res) => {
        console.log(res.data);
        setBook(res.data);
        // console.log(book);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.bookId]);

  let status;

  if (book.status === "yes") {
    status = "Available";
  } else {
    status = book.arrivalDate;
  }

  function onCloseHandler() {
    props.onClose();
  }

  return (
    <>
      <div className={classes.viewCategoryForm}>
        <div className={classes["viewCategoryForm-Header"]}>
          <h3>View Category</h3>
          <CloseIcon className={classes.closeIcon} onClick={onCloseHandler} />
        </div>
        <hr />
        <div className={classes["viewCategoryForm-content"]}>
          <h3>Category's Information</h3>

          <div className={classes.formInput}>
            <label>Book Number:</label>
            <h4>{book.bookNumber}</h4>
          </div>
          <div className={classes.formInput}>
            <label>Book Title:</label>
            <h4>{book.title}</h4>
          </div>
          <div className={classes.formInput}>
            <label>Book Price:</label>
            <h4>{book.price}</h4>
          </div>
          <div className={classes.formInput}>
            <label>Book Availabilty:</label>
            <h4>{status}</h4>
          </div>
          <div className={classes.formInput}>
            <label>Book Cover Image:</label>
            <div className={classes.bookImageDiv}>
              <img
                src={`${BASE_URL}/books/files/${book.coverImg}`}
                alt={`book-cover-${book.title}`}
                className={classes.bookImg}
              />
              <Link to={`${BASE_URL}/books/files/${book.coverImg}`}>View</Link>
            </div>
          </div>
          <div className={classes.formInput}>
            <label>Book Preview PDF:</label>
            <Link
              to={`/admin/viewPdf/${props.bookId}/${book.previewPdf}`}
              target="_blank"
            >
              View
            </Link>
          </div>
          <div className={classes.formInput}>
            <label>Book Complete PDF:</label>
            <Link
              to={`/admin/readPdf/${props.bookId}/${book.completePdf}`}
              target="_blank"
            >
              View
            </Link>
          </div>
          <div className={classes.formInput}>
            <label>Note to Reader PDF:</label>
            <Link
              to={`/admin/viewPdf/${props.bookId}/${book.noteToReader}`}
              target="_blank"
            >
              View
            </Link>
          </div>
          <div className={classes.formInput}>
            <label>Content Table PDF:</label>
            <Link
              to={`/admin/viewPdf/${props.bookId}/${book.contentTable}`}
              target="_blank"
            >
              View
            </Link>
          </div>
          <div className={classes.formInput}>
            .
            {/* <label>Reviews PDF:</label>
            <Link
              to={`/admin/viewPdf/${props.bookId}/${book.reviews}`}
              target="_blank"
            >
              View
            </Link> */}
          </div>
          <div className={classes.formInput}>
            <label>Book Index:</label>
          </div>
          <CKEditor name="index" editor={ClassicEditor} data={book.index} />

          <div className={classes.formControl}>
            <button onClick={onCloseHandler}> Close</button>
          </div>
        </div>
      </div>
      <div className={classes.shadow} id={classes.shadowActive}></div>
    </>
  );
}

export default ViewCategory;
