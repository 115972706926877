// READING SPACE

import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import Header from "../components/Header";
import classes from "./ReadingSpace.module.css";

import bookLogo from "../assets/logos/book-logo-light.png";
import { Link } from "react-router-dom";
import AnimatedPage from "../components/AnimatedPage";
import { motion } from "framer-motion";
import CircularProgress from "@mui/material/CircularProgress";
import Footer from "../components/Footer";
import Carousel from "react-multi-carousel";
import GoToTop from "../components/GoToTop";

const variants = {
  visible: (i) => ({
    opacity: 1,
    transition: {
      delay: i * 0.3,
    },
  }),
  hidden: { opacity: 0 },
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
};

function ReadingSpace() {
  const [isLoading, setIsLoading] = useState(true);
  const [hasBooks, setHasBooks] = useState(true);
  const [myBooks, setMyBooks] = useState([]);

  // GETTING BOOKS PURCHASED BY USER, AVAILABLE TO READ IN READING SPACE
  useEffect(() => {
    console.log(document.cookie);
    axios
      .get(`${BASE_URL}/user/mybooks`, {
        withCredentials: true,
      }) //set unique route with user email in url to get specific books for each user in reading space
      .then((res) => {
        // setAvlBooks(res.data);
        const purchasedBooks = res.data;
        setMyBooks(purchasedBooks);
        if (purchasedBooks.length > 0) {
          setHasBooks(true);
        } else {
          setHasBooks(false);
        }
        setIsLoading(false);
        console.log(purchasedBooks);
      })
      .catch((err) => {
        console.log("Error fetching books:" + err);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <GoToTop />
      <AnimatedPage>
        <div className={classes.mainContainer}>
          <Header />
          <div className={classes.wrapper}>
            <div className={classes.bookName}>
              <img src={bookLogo} alt="whitepages-logo" />
            </div>
            <hr />
            {isLoading ? (
              <div className={classes.loadingDiv}>
                <CircularProgress color="inherit" />
              </div>
            ) : hasBooks ? (
              <Carousel responsive={responsive}>
                {myBooks.map((book, i) => (
                  <motion.div
                    key={book._id}
                    custom={i}
                    animate="visible"
                    variants={variants}
                    initial="hidden"
                  >
                    <div className={classes.cover}>
                      <Link to={`/readPdf/${book._id}/${book.completePdf}`}>
                        <img
                          src={`${BASE_URL}/books/files/${book.coverImg}`}
                          alt={`book-cover-${book.title}`}
                          className={classes.carouselItem}
                        />
                      </Link>
                    </div>
                    <div className={classes.actions}>
                      <p className={classes.avl}>Available</p>
                      <Link to={`/readPdf/${book._id}/${book.completePdf}`}>
                        READ
                      </Link>
                    </div>
                  </motion.div>
                ))}
              </Carousel>
            ) : (
              <div className={classes.readingSpace}>
                {/* if books already in reading space => show books || if NO books in reading space, show below mentioned elements */}
                <p>No books at your reading space.</p>
                <Link to="/explorebooks"> Explore Books</Link>
              </div>
            )}
          </div>
          {/* <ToastContainer /> */}
          <Footer />
        </div>
      </AnimatedPage>
    </>
  );
}

export default ReadingSpace;
