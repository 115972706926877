// LOGIN/SIGNUP PAGE

// On "clicking why a new book" or "books"

import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classes from "./Welcome.module.css";
import PdfViewer from "./PdfViewer.tsx";

import brandLogo from "../assets/logos/brand-logo-light.jpg";
import bookLogo from "../assets/logos/book-logo-light.png";
import AnimatedPage from "../components/AnimatedPage";
import Footer from "../components/Footer";
import ForgotPassword from "../components/WelcomePage/ForgotPassword";
import SignIn from "../components/WelcomePage/SignIn";
import EnterEmail from "../components/OtpVerification/EnterEmail";
import EnterOtp from "../components/OtpVerification/EnterOtp";
import GoToTop from "../components/GoToTop";

function Welcome() {
  const location = useLocation();
  const [forgotPwd, setForgotPwd] = useState(false);
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [selectedPdfUrl, setSelectedPdfUrl] = useState("");

  const handleOpenPdf = async (pdfUrl) => {
    console.log(pdfUrl);
    try {
      const response = await axios.get(pdfUrl, {
        responseType: "blob", // Important: responseType should be set to "blob"
        // withCredentials: true,
      });
      const pdf = response.data;
      const url = URL.createObjectURL(pdf);
      setSelectedPdfUrl(url);
      //   setIsLoading(false);
      setOpenPdfViewer(true);
    } catch (error) {
      console.log("Error fetching PDF file:", error);
    }
  };

  function pdfViewerCloseHandler() {
    setOpenPdfViewer(false);
  }

  const pdfViewer = (
    <PdfViewer pdf={selectedPdfUrl} onClose={pdfViewerCloseHandler} />
  );

  if (openPdfViewer) {
    return pdfViewer;
  }

  return (
    <AnimatedPage>
      <GoToTop />
      <div className={classes.mainContainer}>
        <div className={classes.header}>
          <div className={classes.headerLogo}>
            <Link to="https://kashispace.live/">
              <img className={classes.brand} src={brandLogo} alt="brand-logo" />
            </Link>
          </div>
          <Link
            onClick={() =>
              handleOpenPdf(`${BASE_URL}/user/homepage/files/whyPdf`)
            }
          >
            Introduction
          </Link>
        </div>

        <div className={classes.welcomeBox}>
          {/* LEFT CONTAINER */}
          <div className={classes.leftContainer}>
            <div className={classes.bookName}>
              <img src={bookLogo} alt="whitepages-logo" />
            </div>
            <div className={classes.footerLinks}>
              <Link
                onClick={() =>
                  handleOpenPdf(
                    `${BASE_URL}/user/homepage/files/mainPreviewPdf`
                  )
                }
              >
                Preview
              </Link>
              <Link
                onClick={() =>
                  handleOpenPdf(`${BASE_URL}/user/homepage/files/authorPdf`)
                }
              >
                Author
              </Link>
            </div>
          </div>
          <hr color="black" />
          {/* RIGHT CONTAINER */}
          <div className={classes.rightContainer}>
            {location.pathname.startsWith("/welcome") && (
              <>
                {/* Sign in Form */}
                {!forgotPwd && <SignIn onForgot={() => setForgotPwd(true)} />}
                {/* Forgot Password form */}
                {forgotPwd && (
                  <ForgotPassword onCancel={() => setForgotPwd(false)} />
                )}
              </>
            )}

            {location.pathname.startsWith("/request-otp") && (
              <>
                {/* Email otp request form */}
                <EnterEmail onCancel={() => setForgotPwd(false)} />
              </>
            )}

            {location.pathname.startsWith("/verify-otp") && (
              <>
                <EnterOtp />
              </>
            )}
          </div>
        </div>
        <ToastContainer />
        <Footer />
      </div>
    </AnimatedPage>
  );
}

export default Welcome;
