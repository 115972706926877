import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classes from "../../pages/Welcome.module.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function ForgotPassword(props) {
  const [inputValue, setInputValue] = useState({
    email: "",
  });

  const { email } = inputValue;

  function handleOnChange(event) {
    const { name, value } = event.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  }

  async function resetHandler(e) {
    e.preventDefault();
    const email = inputValue.email;
    console.log(inputValue.email);
    try {
      const { data } = await axios.post(
        `${BASE_URL}/user/forgot-password`,

        { email: email },

        { withCredentials: true }
      );
      console.log(data);
      const { success, message } = data;
      if (success) {
        toast.success(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {/* FORGOT PASSWORD FORM */}
      <ToastContainer />
      <form onSubmit={resetHandler}>
        <h4>Forgot Password</h4>
        <input
          onChange={handleOnChange}
          required
          value={email}
          type="email"
          name="email"
          placeholder="Email Id"
          style={{ margin: "70px 0" }}
        />
        <div className={classes.formActions}>
          <div className={classes.logIn}>
            <button type="submit">
              Get email <ArrowForwardIcon fontSize="small" />
            </button>
          </div>

          <div className={classes.signUp}>
            <Link to="/welcome" onClick={() => props.onCancel()}>
              Cancel
            </Link>
          </div>
        </div>
      </form>
    </>
  );
}

export default ForgotPassword;
